import { Link } from 'gatsby'
import React from 'react'
import styled from '@emotion/styled'

const Post = styled.li`
  position: relative;
  border: 1px solid ${props => props.theme.colors.secondary};
  border-radius: 2px;
  margin: 0 0 1em 0;
  width: 100%;
  transition: background 0.2s;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 49%')};
    margin: 0 0 2vw 0;
  }
  @media screen and (min-width: ${props => props.theme.responsive.medium}) {
    flex: ${props => (props.featured ? '0 0 100%' : '0 0 32%')};
  }
  &:hover {
    background: ${props => props.theme.colors.tertiary};
  }
  a {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
    color: ${props => props.theme.colors.text};
    text-decoration: none;
  }
`

const ImgWrapper = styled.div`
  position: relative;
  overflow: hidden;
  padding-bottom: 60%;
`

const BgImg = styled.img`
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 1;
`

const Title = styled.h2`
  font-size: 1.5em;
  font-weight: 600;
  text-transform: capitalize;
  margin: 1rem 1rem 0.5rem 1rem;
`

const Date = styled.h3`
  margin: 0 1rem 0.5rem 1rem;
  color: gray;
`

const Excerpt = styled.p`
  margin: 0 1rem 1rem 1rem;
  line-height: 1.6;
`

const Card = ({ slug, heroImage, title, publishDate, body, ...props }) => {
  return (
    <>
      {heroImage && body && (
        <Post featured={props.featured}>
          <Link to={`${props.basePath}/${slug}/`}>
            <ImgWrapper>
              <picture>
                <source
                  type="image/webp"
                  src={heroImage.fluid.srcWebp}
                  srcSet={heroImage.fluid.srcSetWebp}
                />
                <BgImg
                  width={heroImage.gatsbyImageData.width}
                  height={heroImage.gatsbyImageData.height}
                  decoding="async"
                  data-src={heroImage.fluid.src}
                  data-srcset={heroImage.fluid.srcSet}
                  alt={heroImage.title} />
              </picture>
            </ImgWrapper>
            <Title>{title}</Title>
            <Date>{publishDate}</Date>
            <Excerpt
              dangerouslySetInnerHTML={{
                __html: body.childMarkdownRemark.excerpt,
              }}
            />
          </Link>
        </Post>
      )}
    </>
  )
}

export default Card
